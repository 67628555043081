// 跟学团
<template>
 <div class="layout-page" v-cloak>
 
   <div class="image-swipt">
    <image-swipe :url='url'></image-swipe>
   </div>
   <!-- 卡片 -->
   <div class="course-card">
      <div class="title-head">
        <van-row>
          <van-col span="14">{{detail.course_name}}</van-col>
          <!-- <van-col span="10" style="text-align:right">
           <div class="photo" > 
              <div class="img" v-for='(item,i) in avater'  :key="item">
              <img   alt="" class="img" :src='item.member.avatar'>  
              </div>
            </div>
          </van-col> -->
        </van-row>
      </div>
      <div  style="text-align:right;font-size:0.29rem;color:#CDCDCD" >{{detail.period_total}}课时</div>
      <div class="course-time">
        <van-row>
          <!-- <van-col span="12">{{currentDate}}</van-col> -->
          <van-col span="12"  class="title-head-tag" style="text-align:right" v-if="detail.is_free=='0'">￥{{detail.discount_price}}</van-col>
          <van-col span="12"  class="title-head-tag" style="text-align:right" v-if="detail.is_free=='1'">免费</van-col>
        </van-row>
      </div>
   </div>
   <!-- 优惠券 -->
   <div class="coupon" v-for='item in discount'  @click='receive(item.id)'  v-if='item.is_receive==0'>
       <div class="money" ><span>￥</span>{{item.discount_amount}}</div>
       <div class="condition">
          <span>满{{ item.meet_amount}}减{{ item.discount_amount}}</span> 
          </br>
         <span>{{item.end_time}}</span> 
       </div>
        <div class="click" >点击领取优惠券</div>
   </div>
   <!-- 切换栏 -->
   <div class="tabs">
    <!-- 切换按钮  -->
    <ul class="tab-tilte">
      <li @click="cur=0" :class="{active:cur==0}"> <span></span> <i>课程详情</i></li>
      <li @click="cur=1" :class="{active:cur==1}"> <span></span> <i>包含课程</i></li>
      <!-- <li @click="cur=2" :class="{active:cur==2}"> <span></span> <i>学员评论</i></li> -->
    </ul>
    <!-- 切换内容 -->
    <div class="tab-content">
      <!-- 课程详情 -->
      <div v-show="cur==0">
                    <div class="image" style="padding-bottom:1.31rem">
                    <img :src="detail.piiic" alt="">
                  </div>
      </div>
      <!-- 包含课程 -->
      <div v-show="cur==1">
        <ul class="course_list">
           <li v-for='item in course_list'  @click="jump" >
            <div class="left">
              <img :src="item.course_image" alt="">
            </div>
            <div class="right">
              <div class="name">{{item.course_name}}</div>
              <div class="names">{{item.remark}}</div>

              <div class="people">{{item.buy_count}}人已购</div>
              <div class="price" v-if="item.is_free==0">￥{{item.discount_price}}</div>
              <div class="price" v-if="item.is_free==1">免费</div>
             
            </div>
           </li>
        </ul>
      </div>
     
      <!-- 学员评论 -->
      <!-- <div v-show="cur==2">
        <ul class="comment">
           <li v-for='item in comments' >
            <div class="left">
                  <img :src="item.member.avatar" alt="">
            </div>
            <div class="right">
              <div class="name">{{item.member.nickname}}</div>
              <p>{{item.content}}</p>
              <div class="times">{{item.created_at}}</div>
            </div>
          </li>
        </ul>
        <div class="imgs" @click='jump'></div>
      </div>              -->
  </div>       
   </div>
  <pay-footer :value='value'></pay-footer>

  <div class="download" v-show="this.download" >
      <div class="box">
        <div class="boxs" v-if='this.aa==false'>是否前往打开（下载）考研通APP？</div>
        <div class="boxs" v-if='this.aa==true'>请点击右上角按钮使用浏览器打开</div>
        <div class="bottomlist">
          <div class="leftlist" @click='close'>取消</div>
  <div class="rightlist" @click="open">打开(下载)</div>
        </div>
        <!-- <div v-show='this.aa==true'>点击右上角...浏览器打开</div> -->
      </div>
  </div>
  <div class="back" v-if="this.download==true"></div>
  <div class="mklos" v-if="this.srcimg==true"><img  class="srcimg" src="../../assets/6285022353_94724262916_b3d861398b827dfef45a9be6450853eb.png" alt=""></div>
 </div>
</template>
<script>
import { Dialog } from 'vant';
import request from "../../plugin/request";
import imageSwipe from "@/components/common/imageSwipe";
import payFooter from "@/components/common/footerPay";
import Cookies from 'js-cookie';
import { Toast } from 'vant';
export default {
  components: {
    imageSwipe,
    payFooter
  },
  data() {
    return {
      srcimg:false,
      download:false,
    aa:false,
      currentSchooldId:'',
      active: 1,
      //默认选中第一个tab
      cur:0 ,
      // 优惠券
      discounts:'',
         avater:'',
      // 详情
      detail:'',
      // 评论
      comments:'',
      // 详情时间
      currentDate:'',
      // 课程列表
      course_list:[],
      // 课程时间
      course_time:'',
      //  传值
      value:{
        // 购买人数
        buy_count:'',
        // 价格
        discount_price:'',
        hidden:'',
        mobile:'',
        wechat_ID:''
      },
      // 图片
      url:'',
      share_id:''
    };
  },
  mounted() {
    let currentQuery = this.$route.query;
    this.currentSchooldId = currentQuery.id;
    this.value.hidden = currentQuery.is_hidden;
    this.value.share_id = currentQuery.share_id;
    this.share_id = currentQuery.share_id;
    this.setToken()
    this.$nextTick(() => {
      this.details();
      this.comment();
      this.discount();
      this.courses();
    });
  },
   methods: {
    close() {
      // window.history.back()   window.history.back();window.location.reload()
      this.download=false 
      this.aa=false
      this.srcimg=false
    },
      //下载页面
    open() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i)=="micromessenger") {
         this.a()
         this.bimg()
      }else{
       try{
       if(navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)){
       window.location.href="kaoyantong://kytapp";
       }else if(navigator.userAgent.match(/android/i)){ 
          window.location.href="zixin://main.app"
       }
       }
     catch(e){
      }
      setTimeout(function(){
        if(navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
          window.location.href="https://itunes.apple.com/cn/app/id1526934426?mt=8"
         }else if(navigator.userAgent.match(/android/i)) {
          window.location.href="https://kytapp.oss-cn-shanghai.aliyuncs.com/app-release.apk"
         }
        },2000);
      }
   },
   a() {
     this.aa=true
   },
   bimg() {
     this.srcimg=true
   },


    async receive(id) {
      const data = await request.post('/app/coupon/receive',{ id:id})
      console.log(data)
      if(data.code==505) {
          this.$router.push({ path:'/login?url=followCourse&id='+this.detail.id})
      } else {
          Toast(data.message);
      }
    },
    setToken(token) {
       var tokens = localStorage.getItem('token');
       console.log(tokens)
       if(tokens!=null) {
          return Cookies.set('Token', tokens)
       } else {
           return Cookies.set('Token', token)
       }
      
    },
    jump() {
       if(this.is_buy==0) {
        Toast('请先报名课程!');  
       } else {
        // this.$router.push({ path:'/download'})   
        this.download=true 
       }
    },
  // 详情
  async details(id) {
      const {data} = await request.post('/app/course/details',{ id:this.currentSchooldId,is_h5:1,share_id:this.share_id})
      this.detail=data
      this.value.buy_count=data.buy_count
      this.value.discount_price=data.discount_price
      this.url=data.course_image
      this.currentDate=data.start_time.substring(0,10).replace(/-/g,".")+'-'+data.end_time.substring(0,10).replace(/-/g,".")
      this.avater=data.lecturer
      this.value.mobile=data.mobile
      this.value.wechat_ID=data.wechat_ID
      this.value.id=data.id
      this.value.url='followCourse'
      this.value.is_buy=data.is_buy,
      this.value.is_free=data.is_free


    },
  // 评论
  async comment() {
      const {data:{data}}=  await request.post('/app/course/commentList',{course_id:this.currentSchooldId,pagesize:100,page:1})
      this.comments=data
      // console.log(data)
    },
  // 优惠券
  async  discount() {
   const {data:{data}} = await  request.post('/app/coupon/lists',{type:0,course_id:this.currentSchooldId,pagesize:1,page:1})
   this.discount=data
   console.log(data)
  },
  // 课程列表
  async courses() {
   const {data} = await  request.post('/app/course/courses',{course_id:this.currentSchooldId})
   var dataa=[]
   for(var i=0;i<data.length;i++) {
     dataa.push(data[i].courses)
   }
   this.course_list=dataa
   }
  },
};
</script>
<style lang="less" scoped> 
 .imgs {
    position: fixed;
    bottom:1.55rem;
    right: .4rem;
    background: url(../../assets/ckpl.png);
    width: 1.36rem;
    height: 1.36rem;
    background-size: 100% 100%;
  }
.course-card {
  // width: 9.2rem;
  // margin:0.3rem;
  // padding:0.35rem 0.49rem;
  padding: 0.53rem 0.4rem 0.43rem 0.4rem;
 border-radius: 0.4rem;
//  background-color: #ebeff2;
//     box-shadow:
//        -3px 0 1px 0px #fff, /*左边阴影*/
//        0 -3px 1px 0px #fff , /*顶部阴影*/
//        0 4px 1px -1px #b9bec1, /*底部阴影*/
//        4px 0 1px -1px  #b9bec1; /*右边阴影*/
box-sizing: border-box;
}

.title-head  {
  width: 100%;
  height: 0.65rem;
  line-height: 0.65rem;
  font-size: 0.45rem;
  font-weight: 600;
  color: #3D3D3D;
}

.photo  {

  height:0.65rem;
}

.img   {
  width: 0.65rem;
  height: 0.65rem;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 0.16rem;
  float: right;
  img {
    width: 100%;
    height: 100%;

  }
} 

.title-head-tag  {
  height: 0.65rem;
  // margin-top:0.13rem;
  line-height: 0.65rem;
  font-size: 0.48rem;
  font-weight: normal;
color: #FF4800;
font-weight: 700;
position: absolute;
right: 0;
}

.course-time  {
  position: relative;
  width: 100%;
  height:0.65rem;
  line-height: 0.65rem;
  font-size: 0.37rem;
  margin-top: 0.11rem;
}

.coupon  {
  width: 9.41rem;
  height: 1.6rem;
  background: url(../../assets/yhq.png);
  background-size: 100% 100% ;
  margin-left: .33rem;
  margin-top:.25rem;
}

.money  {
  width: 2.71rem;
  height: 100%;
  color: #fff;
  line-height:  1.6rem;
  font-size: 0.7rem;
  padding-left: 0.37rem;
  box-sizing: border-box;
  float: left;
}

.money span {
  font-size: 0.48rem;
}

.condition  {
  width: 3.39rem;
  height: 100%;
  float: left;
  font-size: 0.32rem;
  box-sizing: border-box;
  padding-top:.32rem;
  color: #fff;

}

.condition  p  {
  padding:0;
  margin:0
}

.click  {
  height: 0.8rem;
  float: left;
  font-size: 0.35rem;
  color: #fff;
  line-height: 0.8rem;
  border-left: 0.01rem #fff  dashed;
  margin-top:.37rem;
  box-sizing: border-box;
  padding-left:0.24rem ;

}

.tabs  {
  margin-bottom:1.73rem;
  // margin-top:0.4rem
}

.tab-tilte  {
  width: 100%;
  height: 0.72rem;
  line-height: 0.72rem ;
  box-sizing: border-box;
  padding:0 1.41rem;
  display: flex;
  justify-content:space-around;
  font-size: 0.37rem;
 
li {
  position: relative;
  width:1.81rem ;
  height: 100%;
  text-align: center;
  border-radius:0.13rem;
    // background-color: #ebeff2;

    // box-shadow:
    //    -3px 0 1px 0px #fff, /*左边阴影*/
    //    0 -3px 1px 0px #fff , /*顶部阴影*/
    //    0 4px 1px -1px #b9bec1, /*底部阴影*/
    //    4px 0 1px -1px  #b9bec1; /*右边阴影*/
  i {
    position: relative;
    font-style: normal;
    z-index: 1;
    font-size: 0.4rem;
font-weight: normal;
color: #CDCDCD;
  }
}
  
 


}

li.active  {
  // box-shadow:inset 0.03rem 0.03rem 0.05rem 0.05rem #b9bec1;
  
  span  {
    position: absolute;
    bottom: 0rem;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    width: 0.72rem;
    height: 0.1rem;
    background-color: #2775ff;
    border-radius: 0.07rem;
  
  }
  i {
    position: relative;
    font-style: normal;
    z-index: 1;
    font-size: 0.4rem;
font-weight: normal;
color:  #3D3D3D;
;
  }

}


.tab-content  {
  width: 100%;
  margin-top:0.37rem;
  // padding:0 0.4rem;
  box-sizing: border-box;
}

.image  {
  width: 100%;
  background-color: #fff;
  // border-radius: 0.33rem;
  overflow: hidden;
  // background-color: #ebeff2;

  //   box-shadow:
  //      -3px 0 1px 0px #fff, /*左边阴影*/
  //      0 -3px 1px 0px #fff , /*顶部阴影*/
  //      0 4px 1px -1px #b9bec1, /*底部阴影*/
  //      4px 0 1px -1px  #b9bec1; /*右边阴影*/

img  {
  width: 100%;
}
}


.comment  {
  width: 100%;

  li  {
    width: 100%;
    overflow: hidden;
    margin-top:0.41rem;

    .left  {
      float: left; 
      width: 1.35rem;
      height: 100%;

      img  {
        width: 0.84rem;
        height: 0.84rem;
        background-color: #fff;
        border-radius: 50%;
        margin-left: 50%;
        transform: translateX(-50%);
        overflow: hidden;
      }
    }

    .right  {
      float: right;
      width:7.47rem;
      height: 100%;
      border-bottom:0.01rem solid #8c98b3;
      padding-bottom:0.2rem;

      .name  {
        font-size: 0.27rem;
        font-weight: 600;
      }

      p {
        font-size: 0.29rem;
        line-height: 1.5;
         color: #8c98b3;
      }
      .times {
        text-align: right;
        font-size: 0.27rem;
        color: #8c98b3;
      }
     
    }
  }


}
.comment li:last-child {
   .right  {
     border-bottom:none
   }
}

.course_list  {
  width: 100%;
  padding-bottom: 1.31rem;

  li {
    margin-bottom:0.13rem;
    width: 100%;
    padding: 0.4rem 0.4rem 0 0.4rem;
    border-radius: 0.33rem;
    box-sizing: border-box;
  // background-color: #ebeff2;
  //   box-shadow:
  //      -3px 0 1px 0px #fff, /*左边阴影*/
  //      0 -3px 1px 0px #fff , /*顶部阴影*/
  //      0 4px 1px -1px #b9bec1, /*底部阴影*/
  //      4px 0 1px -1px  #b9bec1; /*右边阴影*/
    overflow: hidden;
        .left  {
          float: left;
          width: 3.47rem;
          height:2.27rem;
          background-color: #fff;
          border-radius: 0.13rem 0.13rem 0.13rem 0.13rem;
          opacity: 1;
          overflow: hidden;
    // box-shadow:
    //    -3px 0 1px 0px #fff, /*左边阴影*/
    //    0 -3px 1px 0px #fff , /*顶部阴影*/
    //    0 4px 1px -1px #b9bec1, /*底部阴影*/
    //    4px 0 1px -1px  #b9bec1; /*右边阴影*/

           img  {
             width: 100%;
             height: 100%;

           }
        }

        .right  {
          float: left;
          width: 5.46rem;
          margin-left: 0.27rem;

          .name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 0.37rem;
            font-weight: normal;
            color: #3D3D3D;
            font-weight: 700;
          }
          .names {
            font-size: 0.32rem;
            font-weight: normal;
            color: #3D3D3D;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .time {
            font-size: 0.32rem;
            color: #8c98b3;
            margin-top:0.2rem
          }
          .price  {
            text-align: right;
            margin-top:0.13rem;
            font-size: 0.48rem;
            font-weight: normal;
            color: #FF4800;
            font-weight: 700;
          }
          .people  {
            font-size: 0.29rem;
            font-weight: normal;
            color: #CDCDCD;
            text-align: right;
            margin-top: 0.19rem;
          }

        }
  }
}



.download {
  width: 100%;
  height: 100%;
  background-color:rgba(0,0,0,0.2);
  z-index:1000;
  .box {
    z-index: 999999;
    position: absolute;
    top:40%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 0.4rem;
    text-align: center;
    width: 6.51rem;
    height: 3.7rem;
    background: #FFFFFF;
    border-radius: 0.13rem 0.13rem 0.13rem 0.13rem;
    opacity: 1;
    .boxs {
      padding: 0.8rem 1.39rem 0.73rem 1.36rem;
      line-height: 1.5;
      border-bottom: 0.03rem solid #F0F0F0;
      box-sizing: border-box;
      font-size: 0.37rem;
    }

    .bottomlist {
      width: 100%;
      height: 1.1rem;
      line-height: 1.1rem ;
      position: absolute;
    bottom: 0;
      .leftlist {
        float: left;
        width: 50%;
        border-right:0.03rem solid #F0F0F0;;
        box-sizing: border-box;
        color: #CDCDCD;
      }

      .rightlist {
        float: left;
        width: 50%;
        color: #308bfe;
      }
    }
  }

}
.back{
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: #000;
    opacity: 0.4;
}
.mklos .srcimg{
  width: 4rem;
    height: 4rem;

}
.mklos{
  z-index: 999999;
    position: absolute;
    top: 11.5%;
    right: -15%;
    transform: translate(-50%, -50%);
}
</style>